html,
body {
  height: 100%;
}

.showcase {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url('./img/show2.jpeg') no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.showcase-inner {
  height: 90vh;
  display: flex;
  flex-direction: column-reverse;
  align-items: bottom;
}

.showcase-inner p {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  width: 100vw;
  text-align: center;
  font-size: 8vw;
  color: rgba(255, 255, 255, 0.37);
  -webkit-text-stroke: 1pt;
  -webkit-text-stroke-color: rgb(110, 109, 109);
}

.title {
  font-family: sans-serif;
  letter-spacing: 0.3em;
  width: 100vw;
  height: 180px;
  text-align: center;
  font-size: 4em;
  color: rgb(240, 149, 29);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.downArrow {
  height: 10vh;
  text-align: center;
  opacity: 0.4;
  display: flex;
  flex-direction: column-reverse;
}

.idea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: lightcyan;
  padding: 50px;
}

.idea-inner {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 700px;
  width: 70vw;
  line-height: 200%;
}

.survey-intro {
  max-width: 700px;
  width: 70vw;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 200%;
}

.survey-title {
  margin-top: 40px;
  font-weight: bold;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sortlist-item {
  background-color: white;
  padding-left: 10px;
}

.sortlist-inner {
  display: flex;
  flex-direction: row;
  cursor: grab;
}

.sortlist-inner-icon {
  padding-right: 10px;
}

.card-form {
  opacity: 0.9;
}

.latest-profiles-img {
  width: 40px;
  height: 40px;
}

.form-control::placeholder {
  color: #bbb !important;
}

.error {
  border-color: red;
  border-width: 0.5px;
  border-style: solid;
  padding-bottom: 1px;
}

.error-feedback {
  color: red;
  font-size: 0.8em;
}

.range-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.7em;
  line-height: 100%;
}

.range-label-inner {
  max-width: 10vw;
}

.adminHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url('./img/logoHpts.png') no-repeat;
  background-size: contain;
  background-position: left;
  height: 20vh;
}

.AdminNavBar {
  display: flex;
  flex-direction: row;
  height: 16;
}

.NavBarMenuItem {
  padding-right: 5px;
  cursor: pointer;
  width: 150px;
}

.NavBarDropDown {
  display: flex;
  flex-direction: column;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.adminTitle {
  font-size: 22px;
  padding: 8px;
  background-color: black;
  color: aliceblue;
}

.adminSubTitle {
  flex: 1;
  flex-direction: row;
  font-size: 18px;
  padding: 8px;
  background-color: grey;
  color: aliceblue;
}

.paragrafForm {
  padding: 5px;
}

.showCursor {
  cursor: pointer;
}

/* unvisited link */
a:link {
  color: black;
}

/* visited link */
a:visited {
  color: black;
}

/* mouse over link */
a:hover {
  color: hotpink;
}

/* selected link */
a:active {
  color: blue;
}



@media only screen and (max-width: 640px) and (orientation: portrait) {
  .showcase {
    background: url('./img/show2_Full.jpeg') no-repeat;
    background-size: cover;
    background-position: left;
    height: 30vh;
  }

  .downArrow {
    display: none;
  }

  .showcase-inner {
    height: 30vh;
    display: flex;
    flex-direction: column-reverse;
    align-items: bottom;
  }

  .showcase-inner p {
    font-size: 8vw;
    color: white;
    -webkit-text-stroke: 0.5pt;
    -webkit-text-stroke-color: rgb(83, 83, 83);
  }

  .title {
    height: 100px;
    letter-spacing: 0.3em;
    font-size: 3em;
    color: rgb(240, 149, 29);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .idea {
    padding: 10px;
  }

  .idea-inner {
    padding: 20px;
    max-width: 100vw;
    width: 100vw;
    line-height: 150%;
  }

  .survey-intro {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100vw;
    width: 100vw;
    line-height: 150%;
  }

  .survey-title {
    margin-top: 20px;
    font-weight: bold;
  }

  .sortlist-item {
    line-height: 250%;
  }

  .range-label-inner {
    max-width: 8vw;
  }

  .AdminNavBar {
    display: flex;
    flex-direction: column;
    visibility: hidden;
    height: 0;
  }

}